import { graphql } from 'gatsby';
import React from 'react';

import { Container } from 'react-bootstrap';
import { HeadElement } from '../components/global/HeadElement';

function Privacy({ data }) {
   return (
      <>
         <HeadElement namespace="privacy" />

         {data.content.edges.map(({ node }, idx) => {
            const content = <div key={idx} dangerouslySetInnerHTML={{ __html: node.html }}></div>;
            return (
               <div className="dark-background py-5 imprint">
                  <Container>{content} </Container>
               </div>
            );
         })}
      </>
   );
}

export default Privacy;

export const query = graphql`
   query ($language: String!) {
      locales: allLocale(filter: { language: { eq: $language } }) {
         edges {
            node {
               ns
               data
               language
            }
         }
      }
      content: allMarkdownRemark(filter: { frontmatter: { route: { eq: "/privacy" }, language: { eq: $language } } }) {
         edges {
            node {
               id
               html
               frontmatter {
                  language
               }
            }
         }
      }
   }
`;
